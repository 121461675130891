import { PDFDocument, PageSizes } from 'pdf-lib'
export default {

  methods: {

    async pdfMake (
      options = { header: [], content: [], footer: [], styles: {}, images: {}, watermark: null },
      pageOptions = { pageSize: 'A4', pageMargins: [40, 90, 40, 75], pageOrientation: 'portrait', fontSize: 10 },
      info = { title: '', author: '', creator: '', subject: '' }
    ) {
      return this.$pdfmake.createPdf({
        pageOrientation: pageOptions.pageOrientation, // landscape, portrait
        pageSize: pageOptions.pageSize,
        pageMargins: pageOptions.pageMargins,
        info: {
          title: info.title,
          author: info.author,
          creator: info.creator,
          subject: info.subject
        },
        defaultStyle: {
          font: 'Arial',
          fontSize: pageOptions.fontSize,
          alignment: 'justify',
          characterSpacing: 1,
          lineHeight: 1,
          bold: true
        },
        header: options.header,
        content: options.content,
        footer: options.footer,
        images: {
          invalidImage: `${window.location.origin}/img/invalid-image.png`,
          aljay: `${window.location.origin}/img/invalid-image.png`,
          ...options.images
          // strawberries: {
          //   url: 'http://localhost:8000/api/shared/files/branding/aljay-solutions-small.jpg',
          //   headers: {
          //     Authorization: `Bearer ${this.$store.getters['auth/token']}`
          //   }
          // }
        },
        styles: options.styles,
        watermark: options.watermark
      }, null, {
        Arial: {
          normal: `${window.location.origin}/assets/arial/regular.ttf`,
          bold: `${window.location.origin}/assets/arial/bold.ttf`,
          italics: `${window.location.origin}/assets/arial/italic.ttf`,
          bolditalics: `${window.location.origin}/assets/arial/bold-italic.ttf`
        }
      })
    },

    async pdfLib (
      info = { title: '', author: '', creator: '', subject: '' }
    ) {
      return await PDFDocument.create().then(
        async pdfDocument => {
          pdfDocument.setTitle(info.title, { showInWindowTitleBar: true })
          // pdfDocument.setAuthor(info?.author || ' ')
          // pdfDocument.setCreator(info?.creator || ' ')
          // pdfDocument.setSubject(info?.subject || ' ')
          return pdfDocument
        }
      )
    },

    async pdfLibCopyPages (pdfDocument, pdfBytes, pagesToCopyIndices = [], callback = null) {
      return await PDFDocument.load(pdfBytes, { ignoreEncryption: true }).then(async pagesToCopy => {
        const indices = pagesToCopyIndices.length > 0 ? pagesToCopyIndices : pagesToCopy.getPageIndices()
        await pdfDocument.copyPages(pagesToCopy, indices).then(selectedPagesToCopy => {
          selectedPagesToCopy.forEach((page, index) => {
            if (typeof callback === 'function') {
              callback(page, index, pdfDocument, indices)
            } else {
              page.setSize(PageSizes.A4[0], PageSizes.A4[1])
              pdfDocument.addPage(page)
              pdfDocument.save()
            }
          })
        })
      })
    },

    async calculateEmbededImageSize (pdfDocument, embededImage) {
      const pageHeight = pdfDocument.getHeight()
      const pageWidth = pdfDocument.getWidth()

      const ratio = Math.min((pageWidth / 3) / embededImage.width, (pageHeight / 3) / embededImage.height)

      return {
        width: embededImage.width * ratio,
        height: embededImage.height * ratio
      }
    },

    async pdfToImage (pdf, iPage) {
      const page = await pdf.getPage(iPage)
      const viewport = page.getViewport({ scale: 1 })
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = viewport.width
      canvas.height = viewport.height
      await page.render({
        canvasContext: context,
        viewport: viewport
      }).promise

      return canvas.toDataURL('image/png')
    },

    async convertPdfToImage (pdfBytes) {
      const bufferedData = []
      return new Promise(resolve => {
        window.pdfjsLib.getDocument(pdfBytes).promise.then(
          async pdf => {
            const BATCH_SIZE = 2

            const numPages = Number(pdf.numPages)
            let currentPage = 1

            while (currentPage <= numPages) {
              const batchEnd = Math.min(currentPage + BATCH_SIZE - 1, numPages)
              const promises = []
              for (let iPage = currentPage; iPage <= batchEnd; iPage++) {
                promises.push(this.pdfToImage(pdf, iPage))
              }
              const batchImages = await Promise.all(promises)
              bufferedData.push(...batchImages)

              if (currentPage === numPages) {
                resolve(bufferedData)
              }

              currentPage += BATCH_SIZE
            }
          }
        )
      })
    }

  }
}
